import React from "react";
import styled from "styled-components";

export interface SectionHeaderSubtitleProps {
  className?: string;
  title: string;
  text: string;
}

const SectionHeaderSubtitleInner = (props: SectionHeaderSubtitleProps) => {
  const { title, text } = props;
  return (
    <div className={props.className}>
      <h2 className="section-header-subtitle__title">{title}</h2>
      <p className="section-header-subtitle__text">{text}</p>
    </div>
  );
};

export const SectionHeaderSubtitle = styled(SectionHeaderSubtitleInner)`
  .section-header-subtitle {
    &__title {
      margin-bottom: 15px;
      font-weight: 600;
    }
    &__text {
    }
  }
`;
