import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PageBreadcrumb } from "../../Shared/PageBreadcrumb/PageBreadcrumb";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";
import { useDocumentsHeaderImage } from "./hooks/useDocumentsHeaderImage";
import { SectionHeaderSubtitle } from "../../Shared/SectionHeaderSubtitle/SectionHeaderSubtitle";

export interface DocumentsHeaderProps {
  className?: string;
}

const DocumentsHeaderInner = (props: DocumentsHeaderProps) => {
  const { t } = useTranslation(["common", "documents"]);
  const image = useDocumentsHeaderImage();
  const test = t("documents:header.subheader.texts", { returnObjects: true });

  return (
    <>
      <PageBreadcrumb breadCrumbText={t("common:routes.documents")} />
      <SectionHeader
        title={t("documents:header.title")}
        image={image}
        className={props.className}
        imageAlign="center"
      >
        <SectionHeaderSubtitle
          title={t("documents:header.subheader.title")}
          text={null}
        />
        {test.map(text => (
          <p>{text}</p>
        ))}
      </SectionHeader>
    </>
  );
};

export const DocumentsHeader = styled(DocumentsHeaderInner)`
  p {
    margin-bottom: 10px;
  }
`;
