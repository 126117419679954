import { useStaticQuery, graphql } from "gatsby";

export const useDocumentsHeaderImage = () => {
  const { file } = useStaticQuery(graphql`
    query DocumentsHeaderImage {
      file(
        absolutePath: {}
        relativePath: { eq: "images/documents/documents-header.jpg" }
      ) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return file.childImageSharp.fluid;
};
