import React, { ReactNode } from "react";
import GatsbyImage, { FluidObject } from "gatsby-image";
import styled from "styled-components";
import { HeaderUnderline } from "../HeaderUnderline/HeaderUnderline";
import { Container } from "@material-ui/core";
import { muiTheme } from "../../../lib/Theme";

export interface SectionHeaderProps {
  image: FluidObject;
  title: string;
  imageAlign?: "left" | "right" | "center";

  className?: string;
  children?: ReactNode;
}

const SectionHeaderInner = (props: SectionHeaderProps) => {
  const { image, title, children, imageAlign = "left" } = props;
  return (
    <section className={props.className}>
      <div className="image">
        <GatsbyImage
          imgStyle={{
            objectPosition: imageAlign,
            objectFit: "cover"
          }}
          fluid={image}
        />
      </div>
      <Container>
        <header>
          <HeaderUnderline>{title}</HeaderUnderline>
        </header>
        <div className="sub-header">{children}</div>
      </Container>
    </section>
  );
};

export const SectionHeader = styled(SectionHeaderInner)`
  .image {
    padding-bottom: 40px;
    .gatsby-image-wrapper {
      ${muiTheme.breakpoints.down("sm")} {
        height: 130px;
      }
    }
  }
`;
