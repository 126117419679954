import React, { useMemo } from "react";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { DocumentLinkProps, DocumentLink } from "../DocumentLink/DocumentLink";
import { Container } from "@material-ui/core";

export interface DocumentDownloadListProps {
  className?: string;
}

const DocumentDownloadListInner = (props: DocumentDownloadListProps) => {
  const { t } = useTranslation("documents");

  const documents = useMemo(
    (): DocumentLinkProps[] =>
      Object.values(t("documents", { returnObjects: true })),
    [t]
  );

  return (
    <section className={props.className}>
      <Container>
        {documents.map(document => (
          <DocumentLink key={document.name} {...document} />
        ))}
      </Container>
    </section>
  );
};

export const DocumentDownloadList = styled(DocumentDownloadListInner)`
  margin-top: 35px;

  ${DocumentLink} {
    margin-bottom: 30px;
  }
`;
