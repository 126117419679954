import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Shared/Layout/Layout";
import { DocumentsHeader } from "../components/tlaciva/DocumentsHeader/DocumentsHeader";
import { DocumentDownloadList } from "../components/tlaciva/DocumentDownloadList/DocumentDownloadList";

const TlacivaPage = () => (
  <Layout>
    <DocumentsHeader />
    <DocumentDownloadList />
  </Layout>
);

export default TlacivaPage;
