import * as React from "react";

function SvgTlacivo(props) {
  return (
    <svg
      width="210mm"
      height="297mm"
      viewBox="0 0 210000 297000"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <defs>
        <style>{".tlacivo_svg__fil0{fill:#fefefe;fill-rule:nonzero}"}</style>
      </defs>
      <g id="tlacivo_svg__Vrstva_x0020_1">
        <g id="tlacivo_svg___1964065226416">
          <path
            className="tlacivo_svg__fil0"
            d="M155359.3 113683.9l-31748.9-31749.2c-753-753.3-1782-1162.8-2806.7-1162.8H65241.2c-6565.1 0-11906.3 5341.1-11906.3 11906.3v111654.2c0 6565.1 5341.1 11906.3 11906.3 11906.3l79375-.2c6565.1 0 11906.3-5341.1 11906.3-11906.3v-87841.7c0-1089-461.2-2105.3-1163.1-2806.7l-.1.1zM124772.5 94322l18199.9 18199.9h-14231.2c-2188.4 0-3968.8-1780.4-3968.8-3968.8l.1-14231.1zm19843.7 113979h-79375c-2188.4 0-3968.8-1780.4-3968.8-3968.8V92678c0-2188.4 1780.4-3968.8 3968.8-3968.8l51593.8.2v19843.8c0 6565.1 5341.1 11906.3 11906.3 11906.3l19843.7-.1v83872.9c0 2188.4-1780.4 3968.8-3968.8 3968.8v-.1z"
          />
          <path
            className="tlacivo_svg__fil0"
            d="M129041.4 144801H92356.2c-2026 0-3668.6 1776.9-3668.6 3968.8s1642.5 3968.8 3668.6 3968.8l36685.2-.1c2026 0 3668.6-1776.9 3668.6-3968.8s-1642.5-3968.8-3668.6-3968.8v.1zM129041.4 160676H92356.2c-2026 0-3668.6 1776.9-3668.6 3968.8s1642.5 3968.8 3668.6 3968.8l36685.2-.1c2026 0 3668.6-1776.9 3668.6-3968.8s-1642.5-3968.8-3668.6-3968.8v.1zM129041.4 176551H92356.2c-2026 0-3668.6 1776.9-3668.6 3968.8s1642.5 3968.8 3668.6 3968.8l36685.2-.1c2026 0 3668.6-1776.9 3668.6-3968.8s-1642.5-3968.8-3668.6-3968.8v.1z"
          />
          <circle
            className="tlacivo_svg__fil0"
            cx={77147.4}
            cy={148769.8}
            r={3968.7}
          />
          <circle
            className="tlacivo_svg__fil0"
            cx={77147.4}
            cy={164644.8}
            r={3968.7}
          />
          <circle
            className="tlacivo_svg__fil0"
            cx={77147.4}
            cy={180519.8}
            r={3968.7}
          />
          <path
            className="tlacivo_svg__fil0"
            d="M113166.4 127656H76481.2c-2026 0-3668.6 1776.9-3668.6 3968.8s1642.5 3968.8 3668.6 3968.8l36685.2-.1c2026 0 3668.6-1776.9 3668.6-3968.8s-1642.5-3968.8-3668.6-3968.8v.1z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgTlacivo;
