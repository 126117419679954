import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { muiTheme } from "../../../lib/Theme";
import { CgFileDocument } from "react-icons/cg";
import { Hidden } from "@material-ui/core";
import { HiOutlineDownload } from "react-icons/hi";
import SvgTlacivo from "../icons/Tlacivo";

export interface DocumentLinkProps {
  name: string;
  description: string;
  link?: string;
  className?: string;
}

const ResponsiveDownloadButton = styled.a`
  height: 80px;
  width: 80px;

  min-width: 80px;
  min-height: 80px;

  background: ${props => props.theme.color.primary};
  border-radius: 3px;
  margin-left: ${muiTheme.spacing(3)}px;
  font-size: 40px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DocumentLinkInner = (props: DocumentLinkProps) => {
  const { link, name, description } = props;
  const { t } = useTranslation("documents");
  return (
    <article className={props.className}>
      <div className="document-link__left">
        <Hidden smDown>
          <div className="document-link__icon">
            <SvgTlacivo />
            {/* <CgFileDocument /> */}
          </div>
        </Hidden>
        <div className="document-link__text">
          <h5 className="document-link__name">{name}</h5>
          <p className="document-link__description">{description}</p>
        </div>
      </div>
      <div className="document-link__right">
        <Hidden smDown>
          <a
            className="document-link__button"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("download")}
          </a>
        </Hidden>
        <Hidden mdUp>
          <ResponsiveDownloadButton
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <HiOutlineDownload />
          </ResponsiveDownloadButton>
        </Hidden>
      </div>
    </article>
  );
};

export const DocumentLink = styled(DocumentLinkInner)`
  display: flex;
  justify-content: space-between;
  .document-link {
    &__icon {
      height: 80px;
      width: 80px;

      min-width: 80px;
      min-height: 80px;

      background: ${props => props.theme.color.primary};
      border-radius: 3px;
      margin-right: ${muiTheme.spacing(3)}px;
      font-size: 58px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__left {
      display: flex;
    }
    &__text {
      display: flex;
      flex-flow: column;
      justify-content: center;
    }
    &__name {
      font-size: 1.6rem;
      font-weight: 700;
      margin-bottom: 0.6rem;
    }
    &__description {
      font-size: 1.6rem;
      font-weight: 400;
      color: ${props => props.theme.color.gray};
    }
    &__right {
      display: flex;
      align-items: center;
    }
    &__button {
      display: flex;
      color: white;
      padding: 12px 20px;
      border-radius: 3px;
      background: ${props => props.theme.color.primary};
      font-weight: 600;
      cursor: pointer;
      white-space: nowrap;
    }
  }
`;
